// display flex was causing errors
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active{
    display: block;
    font-size: 125%;
    font-weight: 3000;
}

.carousel{
    .carousel-inner{
        box-shadow: $box-shadow-raised !important;
        border-radius: 10%;
        max-width: 65%;
        max-height: 50%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 75px; 
    }  
}
 


#carousel > div > div > div.carousel-item.active > a > img {
    width: 850px;
    height: 500px;
}

#carousel > div > div > div.carousel-item-next > a > img {
    width: 850px;
    height: 500px;
}


#carousel > div > div > div.carousel-item-prev > a > img {
    width: 850px;
    height: 500px;
}





@media only screen and (max-width: 768px) {
    #carousel > div > div > div.carousel-item.active > a > img {
        width: 250px;
        height: 225px;
    }
    
    #carousel > div > div > div.carousel-item-next > a > img {
        width: 250px;
        height: 225px;
    }
    
    
    #carousel > div > div > div.carousel-item-prev > a > img {
        width: 250px;
        height: 225px;
    }
    


    #carousel {
        margin-top: 75px; 
    }





  }
 
 