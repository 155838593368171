/*!

 =========================================================
 * Now UI Kit React - v1.5.0 based on Now UI Kit - v1.3.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/now-ui-kit-react
 * Copyright 2021 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/now-ui-kit-react/blob/main/LICENSE.md)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

@import 'now-ui-kit/variables';
@import 'now-ui-kit/mixins';


// Core CSS
@import "now-ui-kit/buttons";
@import "now-ui-kit/inputs";
@import "now-ui-kit/checkboxes-radio";
@import "now-ui-kit/progress";
@import "now-ui-kit/badges";
@import "now-ui-kit/pagination";
@import "now-ui-kit/typography";
@import "now-ui-kit/misc";
@import "now-ui-kit/pills";

// components
@import "now-ui-kit/nucleo-outline";

@import "now-ui-kit/tabs";
@import "now-ui-kit/navbar";
@import "now-ui-kit/dropdown";
@import "now-ui-kit/alerts";
@import "now-ui-kit/images";

@import "now-ui-kit/carousel";
@import "now-ui-kit/footers";





@import "now-ui-kit/_media";


// Cards
@import "now-ui-kit/cards";
 
 

// example pages and sections
@import "now-ui-kit/example-pages";
@import "now-ui-kit/sections";


@import "now-ui-kit/responsive";

// React
@import "react/react-differences";

// various custom scss
 







//About page custom css


.aboutContainer{
    max-width: 90%;      
 
}

#root > div > div > div.collapse.show {
    max-width: 100%;
    display: flex;
      
}

 #middleAbout {
    color: black;
    // margin-left: 25px;
    // margin-right: 25px;
    
    // max-width: 30%;
 }

 #leftAbout {
    // margin-left: 4%;
    // max-width: 30%;
    color: black; 
 }


 #rightAbout {
    // max-width: 30%;
    // margin-right: 50px;
    color: black; 
 }

#aboutCard > div.card-body {  
    color: black; 
 } 



 
//projects page custom scss

#root > div > div > div.container > a:nth-child(1) > h3 {
    text-decoration: none;
    color: white;
 }

#root > div > div > div.container > a:hover, a:visited {
    text-decoration: none;
    color: white;

}



.projContainer {
    max-width: 75%;
 
}


#proj > div {
    background-color: rgb(4, 4, 34);
}

#proj > div > div {
    background-color: rgb(4, 4, 34);
}


#proj{
    opacity: 0.6; 
    width: 18rem; 
}

 

 
#proj:hover{
    opacity: 0.9;
}
 


.card-title{
    font-weight: bold;
    font-size: 150%; 
}

.card-img{
    height: 175px;
    width: 300px;
    border-top-left-radius: 10%;
    border-top-right-radius: 10%;
    // opacity: 100% !important;
    margin-bottom: 5px !important;
}




#proj > a {
    color: rgb(63, 61, 212) !important; 
    
    font-weight: bold;
    margin-bottom: 5px !important;
    
     
}

#pipe{
    color: black; 
    font-weight: bold; 
}
 

#root > div > div > div.container > div > div.tab-content.gallery > div.tab-pane.active > div > div > div > a {
    text-decoration: none;
    color: white !important;
}


@media only screen and (max-width: 768px) {
    #proj{
        opacity: 0.6;        
        max-width: fit-content;
      
    }

  }
 


//custom nav css 

#root > nav > div > ul > li:nth-child(-n+3):hover{
    background-color: #89CFF0;
    opacity: .4; 
    > a > p{
        font-weight: bold;
        color: black; 
         

    }
}

#root > nav > div > ul > li > a > p{
    color: white;   
}


///custom social css

#social{
    display:flex;
    justify-content: space-evenly;
    align-items: center;
    max-width: 500px;
    margin: auto; 
    padding-bottom: 20px; 
}
 
#social > a > div > svg > g.social-svg-mask > path {
    opacity: .4;
}

#social > a > div > svg > g.social-svg-mask > path:hover {
    opacity: 1;
}

//main page custom scss



#mainContain > div:nth-child(2) > h1 {
    margin-top: 25px;
 
}


@media only screen and (max-width: 768px) {
    #mainContain{
        height: 200px !important;
    }

    #mainContain > div:nth-child(2) > h1 {
        font-size: 1.78em;
        margin-top: 25px; 
    }

  }
 
  #navbar-brand > h4 {
      color: white !important;
  }