#about-img{
    max-height: 300px;
    max-width: 300px; 
}


// #root > div > div > div.container > div > div > p {
     
//     text-align: left;
     
//     font-weight: 450 !important;
//     top: 50%;
//     left: 50%;
   
//   }


  .media-body > p {
    text-align: left !important;
    justify-content: center;
    margin-left: 20px;
  }


  #mobile {
    display: none;
}


@media only screen and (max-width: 768px) {
 
    .media {
        overflow: scroll;
    }

    #about-img{
        display: none;
        max-height: 50px;
        max-width: 50px; 
    }

    #mobile {
        display: inline-block;
    }



  }
 